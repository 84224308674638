import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FileType2,
  FileDigit,
  CalendarClock,
  Key,
  ToggleLeft,
  Image,
  File,
} from "lucide-react";

import { Column, DatabaseConfig, Table } from "@/interface/config";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";

const CreateFieldModal = ({
  table: selectedTable,
  column: selectedColumn,
  closeAction,
  isEditMode = false
}: {
  table: Table;
  column: Column;
  closeAction: () => void;
  isEditMode?: boolean;
}) => {

  useEffect(() => {
    document.getElementById("fieldName")?.focus();
  }, []);

  const { config, setConfig, handleColumnChange } = useDatabaseConfig()
  const localSelectedTable = config.tables.filter((tab) => tab.id === selectedTable.id)[0]

  const [localTable, setLocalTable] = useState(isEditMode ? localSelectedTable : { ...localSelectedTable, columns: [...localSelectedTable.columns, selectedColumn] });
  const [column, setColumn] = useState<Column>(selectedColumn)

  function handleChange(key: string, value: any) {
    console.log(column)
    if (column == null) return;
    const { table: updatedTable } = handleColumnChange(localTable, column, key, value)
    setColumn(updatedTable.columns.filter((col) => col.id === column.id)[0]);
    setLocalTable(updatedTable);
  }

  // Handle field creation or update with validation
  const handleSaveField = () => {
    setConfig({
      ...config, tables: [...config.tables.map((tab) => {
        if (tab.id === localTable.id) return localTable;
        return tab;
      })]
    })
    closeAction();
  };

  return (
    <>
      {column && (
        <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
          <Card className="field-form mb-4 w-[400px] p-4">
            <CardHeader className="flex w-full justify-start">
              <CardTitle className="w-fit">
                {isEditMode ? "Edit" : "Create"} Field
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                {/* Field Name Input */}
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label htmlFor={`fieldName`} className="w-fit">
                    Field Name
                  </Label>
                  <Input
                    type="text"
                    id={`fieldName`}
                    placeholder="Name"
                    value={column?.name || ""}
                    onChange={(e) =>
                      handleChange(
                        "name",
                        e.target.value,
                      )
                    }
                  />
                </div>

                {/* Field Type Select */}
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Field Type</Label>
                  <Select
                    defaultValue="text"
                    value={column?.type || "text"}
                    onValueChange={(value: string) =>
                      handleChange(
                        "type",
                        value,
                      )
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="text">
                          <div className="flex items-center space-x-2">
                            <FileType2 className="h-4 w-4" />
                            <p> Text</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="number">
                          <div className="flex items-center space-x-2">
                            <FileDigit className="h-4 w-4" />
                            <p> Number</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="dateTime">
                          <div className="flex items-center space-x-2">
                            <CalendarClock className="h-4 w-4" />
                            <p> Date + Time</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="relation">
                          <div className="flex items-center space-x-2">
                            <Key className="h-4 w-4" />
                            <p> Relation</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="boolean">
                          <div className="flex items-center space-x-2">
                            <ToggleLeft className="h-4 w-4" />
                            <p> Boolean</p>
                          </div>
                        </SelectItem>{" "}
                        <SelectItem value="image">
                          <div className="flex items-center space-x-2">
                            <Image className="h-4 w-4" />
                            <p> Image</p>
                          </div>
                        </SelectItem>{" "}
                        <SelectItem value="file">
                          <div className="flex items-center space-x-2">
                            <File className="h-4 w-4" />
                            <p> File</p>
                          </div>
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                {column?.type === "number" && (
                  <div className="col-span-2 flex flex-col space-y-2">
                    <Label className="w-fit">Number Type</Label>
                    <Select
                      value={String(column?.datatype)}
                      defaultValue="INTEGER"
                      onValueChange={(value: string) => {
                        handleChange(
                          "datatype",
                          value,
                        );
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="INTEGER">Integer</SelectItem>
                          <SelectItem value="DECIMAL">Decimal</SelectItem>
                          <SelectItem value="FLOAT">Float</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}
                {column?.type === "relation" && (
                  <>
                    <div className="col-span-2 flex flex-col space-y-2">
                      <Label className="w-fit">Table</Label>
                      <Select
                        value={
                          config.tables
                            .find(
                              (tab) =>
                                column.metadata.isParent ? tab.id ===
                                  column.metadata.childTableId : tab.id ===
                                column.metadata.parentTableId,
                            )?.id
                        }
                        onValueChange={(value) => {
                          handleChange(
                            "referenceTable",
                            value,
                          );
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a Table" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {config.tables
                              .filter((table) => table.id !== localTable.id)
                              .map((table) => (
                                <SelectItem key={table.id} value={table.id}>
                                  {table.name}
                                </SelectItem>
                              ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="col-span-2 flex flex-col space-y-2">
                      <Label className="w-fit">Relationship Type</Label>
                      <Select
                        value={column.metadata.relationType}
                        defaultValue={"ONE-ONE"}
                        onValueChange={(value: string) => {
                          handleChange(
                            "relationType",
                            value,
                          );
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a Type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="ONE-ONE">
                              One to One
                            </SelectItem>
                            <SelectItem value="ONE-MANY">
                              One to Many
                            </SelectItem>
                            <SelectItem value="MANY-MANY">
                              Many to Many
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </>
                )}
              </div>
            </CardContent>
            <CardFooter className="flex justify-end space-x-2 pt-4">
              <Button className="w-fit" variant="outline" onClick={closeAction}>
                Cancel
              </Button>
              <Button
                className="w-fit"
                onClick={handleSaveField}>
                {isEditMode ? "Save" : "Create"}
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </>
  );
};

export default CreateFieldModal;
