import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { Login } from "@/pages/Login";
import { SignUp } from "@/pages/SignUp";
import Table from "@/pages/Database";
import Project from "./pages/Project";
import Workflow from "./components/workflow/Workflow";
import Image from "./pages/Image";
import UI from "./pages/UI";
import { Dashboard } from "./pages/Dashboard";
import { Toaster } from "@/components/ui/toaster";
import ProtectedRoute from "./hooks/auth/ProtectedRoute";
import { AuthProvider } from "./hooks/auth/AuthContext";
// import WorkflowEditor from "./pages/workflow/Workflow";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/project/:projectId",
    element: (
      <ProtectedRoute>
        <Project />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/project/:projectId/data",
        element: <Table />,
      },
      {
        path: "/project/:projectId/workflow",
        element: <Workflow />,
      },
      {
        path: "/project/:projectId/ui",
        element: <UI />,
      },
    ],
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <div className="h-screen w-screen overflow-hidden">
          {" "}
          <Dashboard />
        </div>
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/workflow",
  //   element: (
  //     // <ProtectedRoute>
  //     <WorkflowEditor />
  //     // </ProtectedRoute>
  //   ),
  // },
  {
    path: "/uploadimage",
    element: <Image />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <RouterProvider router={router} />
        <Toaster />
      </div>
    </AuthProvider>
  );
}

export default App;
