import { useState } from "react";
import { DatabaseConfig, Column, Table, Metadata } from "@/interface/config"
import { get } from "@/lib/rest";
import { useConfigStore } from "@/lib/store";
import _ from "lodash";

export function useDatabaseConfig() {
    const config: DatabaseConfig = useConfigStore((state: any) => state.config)
    const setConfig = useConfigStore((state: any) => state.setConfig)
    const oldConfig: DatabaseConfig = useConfigStore((state: any) => state.oldConfig)
    const setOldConfig = useConfigStore((state: any) => state.setOldConfig)
    const project: any = useConfigStore((state: any) => state.project)
    const setProject = useConfigStore((state: any) => state.setProject)

    const [loading, setLoading] = useState(false)

    function onChangeTableReference(
        table: Table,
        column: Column,
        childTableId: any,
    ) {
        return {
            isParent: true,
            parentTableId: table.id,
            childTableId: childTableId,
            parentColumnId: column.id,
            childColumnId: "",
            relationType: "ONE-ONE",
            isPublic: false,
            isHidden: false,
            isHashed: false,
            isRequired: false
        };
    }

    function handleColumnChange(
        table: Table,
        column: Column,
        key: string,
        value: any,
    ) {
        const updatedColumn: Column = { ...column };

        switch (key) {
            case "name":
                updatedColumn.name = value;
                break;
            case "type":
                updatedColumn.type = value;
                break;
            case "datatype":
                updatedColumn.datatype = value;
                break;
            case "referenceTable":
                updatedColumn.metadata = onChangeTableReference(table, updatedColumn, value);
                break;
            case "relationType":
                updatedColumn.metadata.relationType = value;
                console.log(value);
                break;
            case "isPublic":
                updatedColumn.metadata.isPublic = value;
                break;
            case "isRequired":
                updatedColumn.metadata.isRequired = value;
                break;
            case "isHidden":
                updatedColumn.metadata.isHidden = value;
                break;
            case "isHased":
                updatedColumn.metadata.isHashed = value;
                break;
            case "selectOptions":
                updatedColumn.metadata.selectOptions = value;
                break;
        }

        const columns = table.columns.map((col) => {
            if (col.id === updatedColumn.id) return updatedColumn
            return col;
        })


        return {
            table: { ...table, columns }
        }
    }

    function fetch(projectId: any) {
        setLoading(true);
        get({ url: `/api/project/${projectId}` })
        .then((response) => {
            if(response.data.projectData) {
                    setProject(response.data.projectData);
                    const data = response.data.projectData.configuration.new_configuration;
                    const oldData = response.data.projectData.configuration.old_configuration;
                    setConfig(data);
                    setOldConfig(oldData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    return { config, setConfig, oldConfig, project, handleColumnChange, fetch, loading }
}