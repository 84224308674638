import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  CalendarClock,
  ChevronDown,
  FileDigit,
  FileType2,
  Hash,
  Key,
  Loader2,
  Pencil,
  Plus,
  ToggleLeft,
  Trash2,
  Image,
  File,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Column, Table as TableConfig, DatabaseConfig } from "@/interface/config";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { DataEditor } from "./Editor";
import { Badge } from "@/components/ui/badge";
import _ from "lodash";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import CreateTableModal from "./CreateTableModal";
import DeleteTableModal from "./DeleteTableModal";
import CreateColumnModal from "./CreateColumnModal";
import DeleteColumnModal from "./DeleteColumnModal";
import { newColumn, newTable } from "@/lib/baseData";
import { post } from "@/lib/rest";
import { useParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

enum ModalType {
  None = "None",
  CreateTable = "CreateTable",
  EditTable = "EditTable",
  DeleteTable = "DeleteTable",
  CreateColumn = "CreateColumn",
  EditColumn = "EditColumn",
  DeleteColumn = "DeleteColumn"
}

const DatabaseTable = () => {
  interface FieldTypeToIconMap {
    [key: string]: JSX.Element | null;
  }

  const fieldTypeToIconMap: FieldTypeToIconMap = {
    text: <FileType2 className="h-4 w-4" />,
    uuid: <Hash className="h-4 w-4" />,
    number: <FileDigit className="h-4 w-4" />,
    dateTime: <CalendarClock className="h-4 w-4" />,
    relation: <Key className="h-4 w-4" />,
    boolean: <ToggleLeft className="h-4 w-4" />,
    image: <Image className="h-4 w-4" />,
    file: <File className="h-4 w-4" />,
    // Add other mappings as needed
  };

  function getIconByFieldType(fieldType: string | number) {
    return fieldTypeToIconMap[fieldType] || null;
  }

  const { config, setConfig, oldConfig, project, fetch } = useDatabaseConfig();
  const { projectId } = useParams();

  const [isEditorMode, setIsEditorMode] = useState(false);
  const [script, setScript] = useState<any>("");
  const [selectedTable, setSelectedTable] = useState<TableConfig>(config.tables[0]);
  const [selectedColumn, setSelectedColumn] = useState<Column>(newColumn().newColumn);
  const [showModal, setShowModal] = useState<ModalType>(ModalType.None);
  const [syncing, setSyncing] = useState(false);

  useEffect(() => {
    if (config.tables.length === 1 && config.tables[0].columns.length === 0) {
      setSelectedTable(config.tables[0])
    }
  }, []);

  useEffect(() => {
    if(JSON.stringify(config, null, '\t') !== script) {
      setScript(JSON.stringify(config, null, '\t'));
    }
    if(selectedTable) {
      setSelectedTable(_.find(config.tables, { id: selectedTable.id }) || config.tables[0])
    }
  }, [config])

  function updateModal(modalType: ModalType, column?: Column, table?: TableConfig) {
    if (!column && !table) return;
    setShowModal(modalType);
    if (column) setSelectedColumn(column);
    if (table) setSelectedTable(table);
  }

  async function saveScript() {
    setConfig(JSON.parse(script));
    saveConfig(JSON.parse(script));
  }

  async function saveConfig(configuration: any) {
    let data = JSON.stringify({
      newConfiguration: configuration,
    });

    post({ url: `/api/project/${projectId}/configuration`, data })
      .then((response) => {
        toast({
          variant: "default",
          title: "Configuration saved successfully",
        });
        fetch(projectId);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Failed to save configuration",
          description: "Please try again later",
        });
      });
  }

  const syncDatabase = async () => {
    setSyncing(true);
    await saveConfig(config);
    try {
      await post({ url: `/api/database/${projectId}/sync`, data: {} });
      toast({
        variant: "default",
        title: "Database synced successfully",
      });
      fetch(projectId);
      setSyncing(false);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error syncing database",
        description: error.message,
      });
      setSyncing(false);
    }
  };

  const closeModal = () => {
    setShowModal(ModalType.None);
  }

  function renderEditor() {
    return <DataEditor
      configuration={config}
      script={script}
      setScript={setScript}
    />
  }

  function renderColumnHeader(key: string, name: string, type: string, column?: Column) {
    return <Popover key={`${key}`}>
      <TableHead
        key={name}
        className="h-[42px] w-[200px] items-center border-r p-[11px] text-black"
      >
        <div className="flex items-center justify-between">
          {" "}
          <div className="flex items-center">
            <div className="mr-2 w-fit">
              {" "}
              {getIconByFieldType(type)}
            </div>
            <>{name}</>
          </div>
          <PopoverTrigger>
            {" "}
            <>
              {" "}
              <ChevronDown className="h-4 w-4" />
            </>
          </PopoverTrigger>
        </div>
      </TableHead>
      <PopoverContent className="w-[200px] p-2 font-normal">
        {name !== "id" &&
          (
            <>
              <Button
                variant="ghost"
                className="flex w-full items-center justify-start"
                onClick={() => updateModal(ModalType.EditColumn, column)}
              >
                <Pencil className="mr-2 h-4 w-4" />
                <>Edit</>
              </Button>{" "}
            </>
          )}
        <Button
          variant="ghost"
          className="flex w-full items-center justify-start"
          onClick={() => { }}
        >
          <ArrowUpNarrowWide className="mr-2 h-4 w-4" />
          <>Sort Ascending</>
        </Button>
        <Button
          variant="ghost"
          className="flex w-full items-center justify-start"
          onClick={() => { }}
        >
          <ArrowDownNarrowWide className="mr-2 h-4 w-4" />
          <>Sort Descending</>
        </Button>{" "}
        {name !== "id" && (
          <>
            <Separator />
            <Button
              variant="ghost"
              className="flex w-full items-center justify-start text-red-600"
              onClick={() => updateModal(ModalType.DeleteColumn, column)}
            >
              <Trash2 className="mr-2 h-4 w-4" />

              <>Delete</>
            </Button>
          </>
        )}
      </PopoverContent>
    </Popover>
  }

  return (
    <>
      <div className="w-screen p-4 min-h-[calc(100vh-48px)]">
        <div className="flex min-w-[1060px] items-center justify-between">
          <div className="flex items-center">
            <div className="mr-4 flex items-center space-x-2">
              <Switch
                id="editor-mode"
                checked={isEditorMode}
                onCheckedChange={() => setIsEditorMode(!isEditorMode)}
              />
              <Label htmlFor="editor-mode">Editor Mode</Label>
            </div>
            {!syncing ? (
              <Button
                variant={"secondary"}
                className="mr-4 bg-green-300"
                onClick={() => syncDatabase()}
              >
                Sync Database
              </Button>
            ) : (
              <Button
                disabled
                className="mr-4 bg-green-300 text-slate-900"
              >
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Syncing
              </Button>
            )}
            <Button className="mr-4" onClick={() => { }}>
              Fetch Data
            </Button>
            {isEditorMode && <Button
              variant={"default"}
              onClick={() => saveScript()}
              className="mr-4"
            >
              Save
            </Button>}
            {!isEditorMode && <Button
              variant={"default"}
              onClick={() => saveConfig(config)}
              className="mr-4"
            >
              Save
            </Button>}
            <div className="flex flex-col items-start">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="font-semibold">{project.name}</div>
                  </TooltipTrigger>
                  <TooltipContent>Project Name</TooltipContent>
                </Tooltip>
                <div className="font-mono text-xs text-gray-600">{ }</div>
              </TooltipProvider>
            </div>
          </div>
        </div>
        {!isEditorMode &&
          <div className="flex h-full">
            <Card className="mt-6 mr-6 min-w-60 h-full">
              <CardHeader>
                <CardTitle>Tables</CardTitle>
              </CardHeader>
              <CardContent className="p-4 pt-0">
                <nav
                  className="grid gap-4 text-sm text-muted-foreground"
                >
                  {_.sortBy(config.tables, ['name']).map((table: TableConfig, index: number) => (
                    <div key={`${index}tablenav`} className="flex text-left cursor-pointer justify-between items-center" onClick={() => {
                      setSelectedTable(table);
                    }}>
                      <p className={`${table.id === selectedTable.id  ? "bg-green-300 px-2 py-1 rounded-md" : "text-neutral-900 px-2 py-1 rounded-md"}`}>{table.name}</p>
                      <div className="flex justify-center items-center">
                        {oldConfig.tables.find((badgeTable) =>
                          _.isEqual(badgeTable, table),
                        ) ? (
                          <></>
                        ) : (
                          <Badge
                            variant={"outline"}
                            className="ml-3 text-slate-400 h-6"
                          >
                            draft
                          </Badge>
                        )}
                        <Button
                          variant="ghost"
                          className="flex items-center justify-start p-1"
                          onClick={() => updateModal(ModalType.EditTable, undefined, table)}
                        >
                          <Pencil className="h-4 w-6" />
                        </Button>
                        <Button
                          variant="ghost"
                          className="flex items-center justify-start text-red-600 p-1"
                          onClick={() => setShowModal(ModalType.DeleteTable)}
                        >
                          <Trash2 className="h-4 w-6" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </nav>
                <Button
                  variant="outline"
                  size={"icon"}
                  className="h-8 w-8 p-0 mt-6"
                  onClick={() => setShowModal(ModalType.CreateTable)}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
            <div className="mt-6 relative rounded-lg border border-slate-300 overflow-x-scroll">
              <Table className="w-full rounded-t-lg text-[14px] font-medium text-black">
                <TableHeader className="relative h-[42px] w-full bg-slate-100">
                  <TableRow className="relative h-fit w-fit">
                    {renderColumnHeader(`${selectedTable.name}.id`, "id", "uuid")}
                    {selectedTable.columns.map((column: Column, index: number) => (
                      renderColumnHeader(`${selectedTable.name}.${column.name}`, column.name, column.type, column)
                    ))}
                    <TableHead className="h-[30px] w-fit border-r px-2 text-black">
                      <Button
                        variant={"ghost"}
                        className="m-auto h-8 w-8 p-px hover:bg-slate-200"
                        onClick={() => setShowModal(ModalType.CreateColumn)}
                      >
                        <Plus className="h-4 w-4 cursor-pointer rounded-lg p-0" />
                      </Button>
                    </TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {/* Replace this with actual data rows if available */}
                </TableBody>
              </Table>
            </div>
          </div>
        }
        {isEditorMode && (
          renderEditor()
        )}
      </div>
      {
        showModal === ModalType.CreateTable && (
          <CreateTableModal
            table={newTable().newTable}
            closeAction={closeModal}
          />
        )
      }
      {
        showModal === ModalType.EditTable && (
          <CreateTableModal
            table={selectedTable}
            closeAction={closeModal}
            isEditMode={true}
          />
        )
      }
      {
        showModal === ModalType.DeleteTable && (
          <DeleteTableModal
            table={selectedTable}
            closeAction={closeModal}
          />
        )
      }
      {
        showModal === ModalType.CreateColumn && (
          <CreateColumnModal
            table={selectedTable}
            column={newColumn().newColumn}
            closeAction={closeModal}
          />
        )
      }
      {
        showModal === ModalType.EditColumn && (
          <CreateColumnModal
            table={selectedTable}
            column={selectedColumn}
            closeAction={closeModal}
            isEditMode={true}
          />
        )
      }
      {
        showModal === ModalType.DeleteColumn && (
          <DeleteColumnModal
            table={selectedTable}
            column={selectedColumn}
            closeAction={closeModal}
          />
        )
      }
    </>
  );
};

export default DatabaseTable;
