import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  CalendarClock,
  ChevronDown,
  ChevronUp,
  File,
  FileDigit,
  FileType2,
  Hash,
  Image,
  Key,
  Plus,
  ToggleLeft
} from "lucide-react";
import { Column, DatabaseConfig, Table, } from "@/interface/config";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import { newColumn, newTable } from "@/lib/baseData";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import DeleteTableModal from "./DeleteTableModal";
import DeleteColumnModal from "./DeleteColumnModal";
import * as _ from "lodash";

const CreateTableModal = ({
  table,
  closeAction,
  isEditMode = false
}: {
  table: Table;
  closeAction: () => void;
  isEditMode?: boolean;
}) => {
  const [showDeleteTableModal, setShowDeleteTableModal] = useState(false)
  const [showDeleteColumnModal, setShowDeleteColumnModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false);
  const [localTable, setLocalTable] = useState(table);
  const [selectedColumn, setSelectedColumn] = useState(table.columns.length > 0 ? table.columns[0] : null);

  const { config, setConfig, handleColumnChange } = useDatabaseConfig();

  function addField() {
    const {newColumn: column} = newColumn()
    setLocalTable({...localTable, columns: [...localTable.columns, column]})
    setSelectedColumn(column)
  }

  function deleteTable(tableId: string = localTable.id) {
    setConfig(config.tables.filter((tab) => tab.id !== tableId));
    setShowDeleteTableModal(false);
    closeAction();
  }

  function saveTable() {
    if(isEditMode) {
      setConfig({...config, tables: [...config.tables.map((tab) => {
        if(tab.id === localTable.id) return localTable;
        return tab;
      })]})
    } else {
      setConfig({...config, tables: [...config.tables, localTable]})
    }
    closeAction();
  }

  function deleteColumn(columnId: string) {
    setLocalTable({ ...localTable, columns: [...localTable.columns.filter((col) => col.id !== columnId)]})
    setShowDeleteColumnModal(false);
  }

  function handleChange(key: string, value: any) {
    if(selectedColumn == null) return;
    const { table: updatedTable } = handleColumnChange(localTable, selectedColumn, key, value)
    setLocalTable(updatedTable);
    setSelectedColumn(updatedTable.columns.filter((col) => col.id === selectedColumn.id)[0])
  }

  console.log(localTable);

  return (
    <>
      <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
        <Card className="h-fit max-h-[80%] w-fit min-w-[40rem] overflow-y-auto">
          <CardHeader className="flex w-full justify-start">
            <CardTitle className="w-fit">
              {isEditMode ? "Edit" : "Create"} Table
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div>
              <div className="flex flex-col space-y-2">
                <Label htmlFor="name" className="w-fit">
                  Name
                </Label>
                <Input
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={localTable?.name}
                  onChange={(e) =>
                    setLocalTable({ ...localTable, name: e.target.value })
                  }
                />
              </div>
            </div>
            <div>
              <div className="space-y-4 py-3">
                <h4 className="w-fit text-sm font-bold leading-none">Fields</h4>
                <div className="my-4" />
              </div>
              {/* Field Form */}
              {localTable?.columns?.length > 0 &&
                localTable?.columns?.map((column: Column, index: number) => (
                  <div key={index} className="space-y-2">
                    <div
                      className="column-card flex w-full cursor-pointer justify-between rounded-md px-2 py-2 text-[14px] text-slate-500 hover:bg-slate-100"
                      onClick={() => setSelectedColumn(column)}
                    >
                      <div>{column?.name}</div>
                      <div className="flex space-x-4">
                        <div>{getFieldTypeLabel(column?.type)}</div>
                        <div>
                          {column?.metadata?.isRequired
                            ? "Required"
                            : "Not Required"}
                        </div>
                        <div>
                          {column?.metadata?.isHidden === false
                            ? "Not Hidden"
                            : "Hidden"}
                        </div>
                        <div>
                          {column?.metadata?.isHashed
                            ? "Hashed"
                            : "Not Hashed"}
                        </div>
                      </div>
                    </div>
                  {selectedColumn && selectedColumn.id === column?.id && (
                      <div>
                        <Card className="column-form mb-4 p-4">
                          <CardContent className="p-0">
                            <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                              <div className="flex flex-col space-y-2">
                                <Label
                                  htmlFor={`field_name_${index}`}
                                  className="w-fit"
                                >
                                  Name
                                </Label>
                                <Input
                                  type="text"
                                  id={`field_name_${index}`}
                                  placeholder="Name"
                                  value={column?.name}
                                  onChange={(e) =>
                                    handleChange(
                                      "name",
                                      e.target.value,
                                    )
                                  }
                                />
                              </div>
                              <div className="flex flex-col space-y-2">
                                <Label className="w-fit">Type</Label>
                                <Select
                                  defaultValue="text"
                                  value={column?.type}
                                  onValueChange={(value: string) =>
                                    handleChange(
                                      "type",
                                      value,
                                    )
                                  }
                                >
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select a Type" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      <SelectItem value="text">
                                        <div className="flex items-center space-x-2">
                                          <FileType2 className="h-4 w-4" />
                                          <p>Text</p>
                                        </div>
                                      </SelectItem>
                                      <SelectItem value="number">
                                        <div className="flex items-center space-x-2">
                                          <FileDigit className="h-4 w-4" />
                                          <p>Number</p>
                                        </div>
                                      </SelectItem>
                                      <SelectItem value="dateTime">
                                        <div className="flex items-center space-x-2">
                                          <CalendarClock className="h-4 w-4" />
                                          <p> Date + Time</p>
                                        </div>
                                      </SelectItem>
                                      <SelectItem value="relation">
                                        <div className="flex items-center space-x-2">
                                          <Key className="h-4 w-4" />
                                          <p> Relation</p>
                                        </div>
                                      </SelectItem>{" "}
                                      <SelectItem value="boolean">
                                        <div className="flex items-center space-x-2">
                                          <ToggleLeft className="h-4 w-4" />
                                          <p> Boolean</p>
                                        </div>
                                      </SelectItem>
                                      <SelectItem value="image">
                                        <div className="flex items-center space-x-2">
                                          <Image className="h-4 w-4" />
                                          <p> Image</p>
                                        </div>
                                      </SelectItem>{" "}
                                      <SelectItem value="file">
                                        <div className="flex items-center space-x-2">
                                          <File className="h-4 w-4" />
                                          <p> File</p>
                                        </div>
                                      </SelectItem>
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                              {(column.type === "image" ||
                                column.type === "file") && (
                                <div className="flex items-center justify-between py-1">
                                  <div className="flex items-center space-x-2">
                                    <Label className="w-fit">Multiple</Label>
                                    <Switch
                                      checked={column?.metadata?.relationType === "mutliple"}
                                      onCheckedChange={(checked: boolean) =>
                                        handleChange(
                                          "relationshipType",
                                          checked,
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <Label className="w-fit">Public</Label>
                                    <Switch
                                      checked={column?.metadata?.isPublic}
                                      onCheckedChange={(checked: boolean) =>
                                        handleChange(
                                          "isPublic",
                                          checked,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {showDetails && (
                                <>
                                  {column?.type === "number" && (
                                    <div className="col-span-2 flex flex-col space-y-2">
                                      <Label className="w-fit">
                                        Number Type
                                      </Label>
                                      <Select
                                        value={String(column?.datatype)}
                                        defaultValue="INTEGER"
                                        onValueChange={(value: string) => {
                                          handleChange(
                                            "datatype",
                                            value,
                                          );
                                        }}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            <SelectItem value="INTEGER">
                                              Integer
                                            </SelectItem>
                                            <SelectItem value="DECIMAL">
                                              Decimal
                                            </SelectItem>
                                            <SelectItem value="FLOAT">
                                              Float
                                            </SelectItem>
                                          </SelectGroup>
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  )}
                                  {column?.type === "relation" && (
                                    <>
                                      <div className="col-span-2 flex flex-col space-y-2">
                                        <Label className="w-fit">Table</Label>
                                        <Select
                                          disabled={
                                            column?.metadata?.isParent === false
                                          }
                                          value={
                                            _.find(config.tables, {
                                              id: column.metadata.isParent
                                                ? column.metadata.childTableId
                                                : column.metadata.parentTableId,
                                            })?.id
                                          }
                                          onValueChange={(value: string) => {
                                            handleChange(
                                              "referenceTable",
                                              value,
                                            );
                                          }}
                                        >
                                          <SelectTrigger>
                                            <SelectValue placeholder="Select a Table" />
                                          </SelectTrigger>
                                          <SelectContent>
                                            <SelectGroup>
                                              {config.tables
                                              .filter(
                                                (tab) =>
                                                  column.metadata.isParent ? localTable.id !==
                                                  column.metadata.childTableId : localTable.id !==
                                                  column.metadata.parentTableId,
                                              )
                                                .map((tab) => (
                                                  <SelectItem
                                                    key={tab.id}
                                                    value={tab.id}
                                                  >
                                                    {tab.name}
                                                  </SelectItem>
                                                ))}
                                            </SelectGroup>
                                          </SelectContent>
                                        </Select>
                                      </div>{" "}
                                      <div className="col-span-2 flex flex-col space-y-2">
                                          <Label className="w-fit">
                                            Relation Type
                                          </Label>
                                          <Select
                                            value={column?.metadata?.relationType}
                                            defaultValue={"ONE-ONE"}
                                            onValueChange={(
                                              value: string,
                                            ) => {
                                              handleChange(
                                                "relationType",
                                                value,
                                              );
                                            }}
                                          >
                                            <SelectTrigger>
                                              <SelectValue placeholder="Select a Type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                <SelectItem value="ONE-ONE">
                                                  One to One
                                                </SelectItem>
                                                <SelectItem value="ONE-MANY">
                                                  One to Many
                                                </SelectItem>
                                                <SelectItem value="MANY-MANY">
                                                  Many to Many
                                                </SelectItem>
                                              </SelectGroup>
                                            </SelectContent>
                                          </Select>
                                        </div>
                                    </>
                                  )}
                                  {column.type !== "image" &&
                                    column.type !== "file" && (
                                      <>
                                        <div className="flex items-center justify-around space-x-2">
                                          <div className="flex items-center space-x-2">
                                            <Label className="w-fit">
                                              Required
                                            </Label>
                                            <Switch
                                              checked={
                                                column?.metadata?.isRequired
                                              }
                                              onCheckedChange={(
                                                checked: boolean,
                                              ) =>
                                                handleChange(
                                                  "isRequired",
                                                  checked,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {column.type !== "relation" && (
                                          <div
                                            className={`flex items-start justify-around space-x-2`}
                                          >
                                            <div
                                              className={`flex items-center space-x-2`}
                                            >
                                              <Label className="w-fit">
                                                Hidden
                                              </Label>
                                              <Switch
                                                checked={column?.metadata.isHidden}
                                                onCheckedChange={(
                                                  checked: boolean,
                                                ) =>
                                                  handleChange(
                                                    "isHidden",
                                                    checked,
                                                  )
                                                }
                                              />
                                            </div>
                                            {column?.type === "text" ? (
                                              <div className="flex items-center space-x-2">
                                                <Label className="w-fit">
                                                  Hash
                                                </Label>
                                                <Switch
                                                  checked={column?.metadata.isHashed}
                                                  onCheckedChange={(
                                                    checked: boolean,
                                                  ) =>
                                                    handleChange(
                                                      "isHashed",
                                                      checked,
                                                    )
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <div className="min-w-[98px]"></div>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                </>
                              )}

                              <div className="col-span-2 flex justify-between">
                                <div className="flex items-center justify-center">
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    className="w-fit bg-none text-red-500"
                                    onClick={() =>
                                      setShowDeleteColumnModal(true)
                                    }
                                  >
                                    Delete Field
                                  </Button>
                                </div>
                                {showDetails
                                  ? column?.type !== "image" &&
                                    column?.type !== "file" && (
                                      <Button
                                        type="button"
                                        variant="ghost"
                                        className="w-fit"
                                        onClick={() => setShowDetails(!showDetails)}
                                      >
                                        See Less
                                        <ChevronUp className="ml-2.5 h-4 w-4" />
                                      </Button>
                                    )
                                  : column?.type !== "image" &&
                                    column?.type !== "file" && (
                                      <Button
                                        onClick={() => setShowDetails(!showDetails)}
                                        type="button"
                                        variant="ghost"
                                        className="w-fit"
                                      >
                                        See More
                                        <ChevronDown className="ml-2.5 h-4 w-4" />
                                      </Button>
                                    )}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    )}
                </div>
                ))}
              <div className="my-4" />
              <Button
                type="button"
                variant="secondary"
                className="w-full"
                onClick={() => {
                  addField();
                }}
              >
                <Plus className="mr-4" /> Add Field
              </Button>
            </div>
          </CardContent>
          <CardFooter className="flex justify-end space-x-2">
            <Button
              className="w-fit"
              variant="destructive"
              onClick={() => {
                deleteTable();
              }}
            >
              Delete
            </Button>
            <Button
              className="w-fit"
              variant="outline"
              onClick={() => {
                closeAction();
              }}
            >
              Cancel
            </Button>
            <Button className="w-fit" onClick={saveTable}>
              {isEditMode ? "Save" : "Create"}
            </Button>
          </CardFooter>
        </Card>
      </div>

      {showDeleteColumnModal &&(
          <DeleteColumnModal
            table={localTable}
            column={selectedColumn}
            closeAction={() => setShowDeleteColumnModal(false)}
            confirmAction={deleteColumn}
          />
        )}

      {showDeleteTableModal && (
          <DeleteTableModal
            table={localTable}
            closeAction={() => setShowDeleteTableModal(false)}
            deleteTable={() => deleteTable(localTable.id)}
          />
        )}
    </>
  );
};

export default CreateTableModal;

// Utility function to get column type label based on type
function getFieldTypeLabel(type: string) {
  switch (type) {
    case "text":
      return "Text";
    case "autoIncrementIntegerId":
      return "Auto-increment integer id";
    case "number":
      return "Number";
    case "dateTime":
      return "Date + Time";
    case "relation":
      return "Relation";
    case "boolean":
      return "Boolean";
    case "image":
      return "Image";
    case "file":
      return "File";
    default:
      return "";
  }
}
