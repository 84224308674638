import { Column, Table } from "@/interface/config";
import { uuid } from "@/lib/utils";

export function newColumn() {
    const columnId = uuid();
    const newColumn: Column = {
        id: uuid(),
        name: "",
        type: "text",
        datatype: "TEXT",
        metadata: {
            isParent: false,
            isPublic: false,
            isHidden: false,
            isHashed: false,
            isRequired: false,
            parentTableId: "",
            childTableId: "",
            parentColumnId: "",
            childColumnId: "",
            relationType: ""
        }
    }

    return {columnId, newColumn}
}

export function newTable() {
    const tableId = uuid();
    const newTable: Table = {
        id: tableId,
        name: ``,
        columns: []
    };

    return {tableId, newTable};
}